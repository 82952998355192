import React from 'react';

import withBg from '../../helpers/withBg';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import TrustpilotHorizontal from '../../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../../components/Sidebar/SidebarStaticPage';
import RedMorelyImg from '../../components/Image/RedMorelyImg';
import './styles.css';
import GetCallBackForm from '../../components/GetCallBackForm/GetCallBackForm';

const data = {
  title: 'Refer and earn at travelopod',
  seoTitle: 'Refer and earn at travelopod | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class referAndEarnAtTravelopod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Refer and Earn at Travelopod</h1>
                <p>
                  Refer your friends at Travelopod and earn USD 25 on each
                  booking. Please fill up the form and send us the details. We
                  will validate the details and send out a check within 10
                  business days.
                </p>
                <GetCallBackForm />
                <h2>Promotion Terms and Conditions</h2>
                <ul>
                  <li>
                    To participate in this promotion you must recommend a friend
                    , colleague or business contact to Travelopod. To be
                    eligible for the scheme the person being referred must not
                    be currently in negotiations with us
                  </li>
                  <li>
                    Where the company is referred by more than one person, we
                    will operate a first come first served policy. If such a
                    case arises we will notify you as soon as possible that your
                    referral has already been made.
                  </li>
                  <li>The recommended party must book with Travelopod</li>
                  <li>
                    Only referrals submitted through the website form will be
                    accepted.
                  </li>
                  <li>
                    Only referrals that meet the qualifying booking criteria
                    will be eligible for payment.
                  </li>
                </ul>
                <RedMorelyImg
                  alt="red morley he
                  witt 632745 unsplash"
                />
                <ul>
                  <li>
                    Criteria for eligibility is outlined below;
                    <ul>
                      <li>
                        Booking must be made by a referred party who is not
                        currently known to Travelopod.
                      </li>
                      <li>Complete booking, with payment</li>
                    </ul>
                  </li>
                  <li>
                    Payments under the referral scheme will only be made once a
                    qualifying booking has been made and paid for, within the
                    terms outlined above.
                  </li>
                  <li>
                    Payments will not be made on a referral booking made after
                    the closing date for this scheme.
                  </li>
                  <li>
                    Travelopod will notify you once your referral has made a
                    qualifying booking.
                  </li>
                  <li>
                    We will also inform you should your referral fail to make a
                    qualifying booking within the terms of the promotion.
                  </li>
                  <li>
                    There are no limits to the number of referrals you can make
                  </li>
                  <li>
                    You will be responsible for any tax or social security due,
                    if any, in your tax jurisdiction
                  </li>
                  <li>
                    By agreeing to the Terms and Conditions of this referral
                    scheme, you confirm that you are authorized to supply the
                    contact information for the referred party to Travelopod to
                    be used for marketing of their services.
                  </li>
                  <li>
                    Any personal data collected for the purpose of this
                    promotion will be processed in accordance with the
                    provisions of Travelopod’s Privacy Policy
                  </li>
                  <li>
                    Travelopod Travel standard booking terms and conditions
                    apply to all bookings.
                  </li>
                  <li>
                    We reserve the right to withdraw the scheme and its benefits
                    at any time and without notice.
                  </li>
                </ul>
              </div>
            </div>
            <SidebarStaticPage isCanada phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(referAndEarnAtTravelopod);
